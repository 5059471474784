@import '~app/mixins';

.priceList {
  color: $color-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;

  .pricing {
    .rate {
      display: flex;
      align-items: center;

      > div {
        padding: 10px 0 10px 13px;
        text-align: left;

        &:first-child {
          width: 175px;
          padding-left: 25px;
        }

        &:nth-child(2) {
          width: 130px;
        }

        &:nth-child(3) {
          width: 130px;
        }

        &:last-child {
          width: 175px;
        }
      }
    }

    .rates {
      color: $color-grey;
      background-color: white;
      border: 1px solid $color-grey-lighter;
      font-size: 13px;
      margin-bottom: 10px;
      .header {
          font-weight: bold;
      }
      .rate {
        > div {
          border-right: 1px solid $color-grey-lighter;
          border-bottom: 1px solid $color-grey-lighter;
          &:last-child {
            border-right-style: none;
          }
        }
      }
    }
  }
}
